import axios from './axios'
import store from '../store'

export const getDeviceInfo = (path, params) => {
  return axios.request({
    baseURL: path,
    url: '/api/getdeviceinfo',
    method: 'get',
    params,
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}


export const getStatisticYearInfo = (path, params) => {
  return axios.request({
    baseURL: path,
    url: '/api/getstatisticyearinfo',
    method: 'get',
    params,
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getStatisticMonthInfo = (path, params) => {
  return axios.request({
    baseURL: path,
    url: '/api/getstatisticmonthinfo',
    method: 'get',
    params,
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getYear = (path) => {
  return axios.request({
    baseURL: path,
    url: '/api/file/',
    method: 'get',
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getKeti = (path, year) => {
  return axios.request({
    baseURL: path,
    url: `/api/file/${year}`,
    method: 'get',
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getKetiDate = (path, year, keti) => {
  return axios.request({
    baseURL: path,
    url: `/api/file/${year}/${keti}`,
    method: 'get',
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getFileName = (path, year, keti, ketiname) => {
  return axios.request({
    baseURL: path,
    url: `/api/file/${year}/${keti}/${ketiname}`,
    method: 'get',
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getFileData = (path, year, keti, ketiname, data) => {
  return axios.request({
    baseURL: path,
    url: `/api/file/${year}/${keti}/${ketiname}/${data}`,
    method: 'get',
    timeout: 1000 * 600,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8',
    onUploadProgress: function (progressEvent) {
      console.log(progressEvent)

    },

    // `onDownloadProgress` allows handling of progress events for downloads
    // browser only
    onDownloadProgress: function (progressEvent) {
      let val = {
        loaded: progressEvent.loaded,
        total: progressEvent.total
      }

      console.log('load: ', val.loaded, 'total: ', val.total)

      store.commit('updateProgress', val)
    },
  })
}

export const getTitleNoList = (path) => {
  return axios.request({
    baseURL: path,
    url: `/api/gettitlenolist`,
    method: 'get',
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const getFileContent = (path, year, keti, ketiname, data) => {
  return axios.request({
    baseURL: path,
    url: `/${year}/${keti}/${ketiname}/${data}`,
    method: 'get',
    timeout: 1000 * 600,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8',
  })
}

export const getProgramInfo = (path, params) => {
  return axios.request({
    baseURL: path,
    url: '/api/getprograminfo',
    method: 'get',
    params,
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const setDeviceStart = (path, data) => {
  return axios.request({
    baseURL: path,
    url: '/api/updateallwriteinfo',
    method: 'post',
    data,
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}

export const accountLogin = (path, data) => {
  return axios.request({
    baseURL: path,
    url: '/api/checklogin',
    method: 'post',
    data,
    timeout: 1000 * 10,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8'
  })
}