import axios from 'axios'

class HttpRequest {
    constructor() {
        
    }
    
    interceptors (instance) {
        // Add a request interceptor
             instance.interceptors.request.use(function (config) {
            // Do something before request is sent
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });

        // Add a response interceptor
        instance.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            //console.log(response, 'response')
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(error, 'error')
            return Promise.reject(error);
        });
    }

    async request(options) {
        const instance = axios.create();
        this.interceptors(instance)
        return await instance(options)
    }
}

export default new HttpRequest()